.styles_image__mAxlD{margin-top:-3.125rem}
.styles_section__oebwN{width:100%;padding-top:6.25rem}.styles_container__VvwWi{display:flex;justify-content:center;gap:2.9375rem}.styles_title__R0eDg{font-weight:900;font-size:2.125rem;white-space:pre}.styles_image__gqsij{order:1;width:39.375rem}.styles_image__gqsij img{width:100%;height:auto}.styles_content__hm7Ds{order:2;display:flex;flex-direction:column;justify-content:center;gap:1.25rem;max-width:32.8125rem}.styles_description__K4_Nn{color:#7c8196;font-size:1.125rem;max-width:31.5625rem}@media only screen and (max-width: 767px){.styles_section__oebwN{padding-top:1.25rem}.styles_container__VvwWi{display:grid;grid-template-columns:1fr;grid-gap:1.875rem;gap:1.875rem;grid-column-gap:unset;column-gap:unset}.styles_title__R0eDg{font-size:1.6875rem}.styles_image__gqsij{width:100vw;margin-left:calc(50% - 50vw);overflow:hidden;order:2}.styles_image__gqsij img{width:100%;height:auto}.styles_content__hm7Ds{width:100%;padding:0;order:1}.styles_description__K4_Nn{padding-bottom:1.25rem}.styles_title__R0eDg{line-height:1.11}}
.styles_section__UrE0v{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:3.125rem;padding:3.125rem 0 2.5rem 0;background-color:#f6f8fa}.styles_title__SzDr2{max-width:51.25rem;text-align:center;font-weight:900;color:#262f4d}.styles_mobileOnly__naGLd{display:none}@media only screen and (max-width: 767px){.styles_desktopOnly__2YMoS{display:none}.styles_mobileOnly__naGLd{display:flex}.styles_title__SzDr2{font-size:1.6875rem;color:#262f4d}.styles_section__UrE0v{padding:2.5rem 1.25rem 0 1.25rem;background-color:#f6f8fa;gap:1.875rem}.styles_stats__MEqWS{display:flex;flex-direction:column;gap:1.25rem;width:100%;background-color:#fff;border:1px solid #262f4d;border-radius:1.75rem;padding:1.25rem 0.9375rem}.styles_statsTitle__hsi21{font-size:1.6875rem;font-weight:bold;color:#262f4d}}
.styles_stats__iOWkH{display:grid;grid-template-columns:repeat(5, 1fr);justify-content:center;align-items:start;background-color:#fff;border-radius:1.875rem;padding:3.4375rem 0;border:1px solid #000;max-width:min(100% - 200px,1500px)}.styles_statsBlock__g60aF{display:flex;flex-direction:column;align-items:center;padding:0 1.875rem;position:relative}.styles_statsBlock__g60aF+.styles_statsBlock__g60aF::after{content:"";position:absolute;top:1.75rem;left:0;width:1px;height:3.125rem;background-color:#d7dcea}.styles_statsBlockTitle__zhuQW{font-size:4.3125rem;line-height:4.3125rem;width:10.625rem;text-align:center;flex-wrap:nowrap;display:flex;justify-content:center}.styles_statsBlockTitleWrapper__X_gD9{display:flex;align-items:center;height:6.5rem}.styles_statsBlockTitle__zhuQW span{line-height:inherit;font-weight:900}.styles_statsBlockDescription__aQBib{font-size:1.3125rem;text-align:center}
.styles_root__WiiXA{max-width:100vw}.styles_carousel__YURoZ{width:21.4375rem}.styles_pagination__N7MRr{display:flex;flex-direction:row;align-items:center;margin:1.625rem 0 0;justify-content:center;gap:0.3125rem}.styles_bullet__1eWia{width:1.3125rem;height:0.125rem;opacity:.4;background-color:#262f4d}.styles_bulletActive___3_wh{opacity:1}.styles_sliderWrapper__gSqfu{box-shadow:0 1.25rem 2.5rem 0 rgba(0,0,0,.15);border-radius:1.625rem;background-color:#fff}.styles_slide__1Cii_{display:flex;flex-direction:row;align-items:baseline;justify-content:space-around;padding:1.875rem 0 1.25rem 0}.styles_slideItem__ABZq7{display:flex;flex-direction:column;align-items:center;position:relative;min-width:50%}.styles_slideItem__ABZq7+.styles_slideItem__ABZq7::after{content:"";position:absolute;top:1.25rem;left:0;width:1px;height:2.4375rem;background-color:#d7dcea}.styles_slideItemTitle__w2F2S{font-size:3.0625rem;line-height:3.0625rem}.styles_slideItemTitleWrapper__hBPBQ{display:flex;align-items:center;height:4.625rem}.styles_slideItemTitle__w2F2S span{line-height:inherit;color:#0068ff}.styles_slideItemDescription__kG9gb{font-size:1rem;text-align:center}
.styles_section__F7cJq{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:4.6875rem;padding-top:3.125rem;padding-bottom:5.75rem}.styles_container__QPNk3{width:100%;display:flex;justify-content:center;gap:2.9375rem}.styles_topTitle__d092D{color:#ff5001}.styles_title__ul6M5{font-size:2.125rem;white-space:pre-wrap;font-weight:900}.styles_image__XXr_y{order:1;width:39.375rem}.styles_image__XXr_y img{width:100%;height:auto}.styles_content__bfnSv{order:2;display:flex;flex-direction:column;gap:1.25rem;position:relative}.styles_contentTitle__GCrY0{font-size:2.125rem;font-weight:bold}.styles_contentStep__ixb91{font-size:1.125rem;color:#7c8196;position:relative;max-width:31.5625rem}.styles_contentStep__ixb91 span{font-weight:bold;margin-right:0.3125rem;position:relative;z-index:1}.styles_contentStep__ixb91 img{position:absolute;top:1.25rem;left:-10px}@media only screen and (max-width: 767px){.styles_section__F7cJq{padding-top:4.5rem;gap:1.125rem}.styles_title__ul6M5{font-size:1.6875rem;padding:0 1.25rem}.styles_container__QPNk3{grid-template-columns:1fr;grid-gap:0.3125rem;gap:0.3125rem;display:grid;padding-left:1.25rem;padding-right:1.25rem}.styles_content__bfnSv{order:1}.styles_contentTitle__GCrY0,.styles_contentStep__ixb91{font-size:1.0625rem}.styles_image__XXr_y{order:2;width:100%}}
.styles_root__sWRh8{max-width:100vw;width:100%}.styles_pagination__PxeAz{display:flex;flex-direction:row;align-items:center;margin:2.5rem 0 0;justify-content:center;gap:0.3125rem}.styles_bullet__DWep1{width:1.3125rem;height:0.125rem;background-color:#c1c3d2}.styles_bulletActive__aHKS2{background-color:#262f4d}
.styles_feature__o_svV{width:20rem;display:flex;flex-direction:column;align-items:center;justify-content:flex-start}.styles_feature__o_svV.styles_fullWidth__6qrBr{width:100%;justify-content:center}.styles_featureImage__B1pQZ{display:flex;height:81px;justify-content:center;align-items:center}.styles_featureTitle__MTwAv{padding-top:30px;font-size:1.5rem}.styles_featureDescription__RNfTx{max-width:12.8125rem;text-align:center;font-size:1.125rem;font-weight:normal;margin-top:1.25rem;margin-bottom:1.25rem;color:#7c8196}
.styles_section__MeN9l{width:100%;padding:8.125rem 0 4.375rem 0}.styles_container__3H_0W{display:flex;flex-direction:column;align-items:center;justify-content:center}.styles_title__KG8M7{font-weight:900;text-align:center}.styles_content__dnsFo{display:flex;flex-direction:row;margin-top:3.5rem}.styles_mobileOnly__rMiIe{display:none}@media only screen and (max-width: 767px){.styles_section__MeN9l{padding:5.8125rem 0 3.75rem 0}.styles_title__KG8M7{font-size:1.6875rem;padding:0 2.5rem}.styles_desktopOnly__rOsMI{display:none}.styles_mobileOnly__rMiIe{display:flex}}
.styles_body__dOTvx{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;border-radius:2.4375rem;height:roRem(271);background-color:#faf3ec;position:relative;overflow:hidden;height:270px}.styles_bg__V_A_C{position:absolute;top:0;left:0;right:0;bottom:0;z-index:0}.styles_title__cwQJO{font-size:4.5rem;font-weight:bold;margin:0;position:relative;z-index:1}.styles_subtitle__KqAbP{font-size:1.75rem;font-weight:bold;margin:0;margin-top:0.3125rem;position:relative;z-index:1}@media only screen and (max-width: 767px){.styles_body__dOTvx{height:auto;padding:2.125rem 0.625rem;border-radius:0}.styles_title__cwQJO{font-size:2.25rem}.styles_subtitle__KqAbP{font-size:1.25rem}.styles_container__JGASJ{padding:0}}
/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_840cdc';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/ae80e08d9fcae03a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_840cdc';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/4486f70b101e60d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_840cdc';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/3e57fe4abb1c4cae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_840cdc';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/dcc209c0b1ab30af-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Playfair_Display_Fallback_840cdc';src: local("Times New Roman");ascent-override: 97.25%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 111.26%
}.__className_840cdc {font-family: '__Playfair_Display_840cdc', '__Playfair_Display_Fallback_840cdc';font-style: normal
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/11e1fda3ed3d2d4b-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/5300a488926952af-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/cde8d8e9e2fa4e01-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/b7d1d78488e78246-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/55d8df7c483c276c-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/3dd55c8c99f06f9d-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/4babd15b67593ad1-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/a322e6ae2d771f48-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/33e03f56d9ecb3ae-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/1720c35774df681d-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/357a42453f55c671-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/23f5a359ff2ad3b7-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/bff4558343f15e48-s.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__sofiaPro_bf5ac0';
src: url(/_next/static/media/a9f2a8a656a13263-s.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: '__sofiaPro_Fallback_bf5ac0';src: local("Arial");ascent-override: 71.98%;descent-override: 23.99%;line-gap-override: 0.00%;size-adjust: 104.19%
}.__className_bf5ac0 {font-family: '__sofiaPro_bf5ac0', '__sofiaPro_Fallback_bf5ac0'
}

.styles_card__Zu1ZH{border-radius:1rem;box-shadow:0 1.25rem 2.5rem 0 rgba(214,221,237,.8);overflow:hidden;display:flex;flex-direction:column;cursor:pointer;text-decoration:none;color:#000}.styles_card__Zu1ZH:hover .styles_button__vaeq2{text-decoration:none}.styles_image__IVAbZ{width:100%;height:12rem;padding:0 1.25rem;display:flex;justify-content:center;align-items:center}.styles_image__IVAbZ img{max-width:100%;max-height:100%;object-fit:contain;object-position:center}.styles_content__4dzQ_{display:flex;flex-direction:column;min-height:10.625rem;flex:1 1;padding:0.9375rem}.styles_title__VZwLd{font-size:1.0625rem;font-weight:900;line-height:1.18;margin-bottom:0.5rem}.styles_title__VZwLd br{display:none}.styles_description__ZPlqj{font-size:0.8125rem;line-height:1.31}.styles_pwMention__zjbuw{color:#ff5001}.styles_link__aznG_{padding-top:10px;width:-moz-fit-content;width:fit-content;padding:0;margin-top:10px;color:#0068ff;text-decoration:underline}.styles_link__aznG_:hover{background-color:rgba(0,0,0,0);text-decoration:underline}
.styles_body__FF0YT{padding:0 1.25rem}.styles_grid__hLCwH{display:grid;grid-template-columns:repeat(3, 1fr);grid-column-gap:1.875rem;grid-row-gap:1.875rem}@media only screen and (max-width: 767px){.styles_body__FF0YT{padding:0}.styles_grid__hLCwH{display:flex;flex-direction:column;gap:1.875rem}}
.styles_wrapper__94jA7{padding-top:1.6875rem;color:#000}.styles_headline__BCeg8{margin-bottom:5.25rem}.styles_catalog__7LL3r{margin-bottom:9.125rem}@media only screen and (max-width: 767px){.styles_wrapper__94jA7{padding-top:0rem}.styles_headline__BCeg8{margin-bottom:1.875rem}.styles_catalog__7LL3r{margin-bottom:1.875rem}}
.styles_section__HzmcA{padding-top:4.375rem}.styles_container__CYwjR{display:flex;flex-direction:column;align-items:center}.styles_title__bRdnG{max-width:61.25rem;font-weight:900;text-align:center;margin-bottom:3.5rem}.styles_pwMention__vXS_L{color:#ff5001}.styles_link__0fhQ4{margin-top:3.375rem}@media only screen and (max-width: 767px){.styles_section__HzmcA{padding:0}.styles_title__bRdnG{font-size:1.6875rem;margin-bottom:1.75rem}}
.styles_section__c1lgd{width:100%;padding-top:6.25rem;padding-bottom:5.625rem}@media only screen and (max-width: 767px){.styles_section__c1lgd{padding-top:1.875rem;padding-bottom:2.5rem}}.styles_title__cJ2YS{font-size:3.625rem;text-align:center;margin:0 auto;white-space:pre-wrap;font-weight:900}@media only screen and (max-width: 767px){.styles_title__cJ2YS{font-size:42px;text-align:left;white-space:unset}}.styles_subtitle__Qz8JG{text-align:center;margin-top:15px;font-size:1.375rem;color:#7c8196;white-space:pre-wrap}@media only screen and (max-width: 767px){.styles_subtitle__Qz8JG{text-align:left;white-space:normal}}.styles_buttons__0RgtB{display:flex;justify-content:center;margin-top:30px;gap:16px}.styles_buttons__0RgtB button:last-child{color:#000}.styles_getStarted__Nt1f_{text-align:center;margin-top:9px;font-size:.875rem;color:#000}.styles_getStarted__Nt1f_ a{color:#000;position:relative;width:calc(100% + 30px)}.styles_getStarted__Nt1f_ a svg{position:absolute;top:50%;right:-15px;transform:translateY(-45%)}.styles_talkToUs__6RONi{display:flex;align-items:center;gap:10px}.styles_videoArrow__0XNq4{width:100%;display:flex;justify-content:flex-end;margin-top:3.125rem}
.styles_wrapper__lYnUy{max-width:1100px;width:100%;height:618px;position:relative;margin:0 auto;box-shadow:0 1.25rem 2.5rem 0 rgba(214,221,237,.8);border-radius:18px;margin-top:10px}@media only screen and (max-width: 767px){.styles_wrapper__lYnUy{height:365px}}.styles_wrapper__lYnUy video{object-fit:cover;object-position:top;border-radius:18px}.styles_playButton___veH8{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);cursor:pointer;box-shadow:0 8px 8px 0 rgba(0,0,0,.1);border-radius:50%;transition:transform .2s ease-in-out}.styles_playButton___veH8:hover{transform:translate(-50%, -50%) scale(1.05)}.styles_playButton___veH8:active{transform:translate(-50%, -50%) scale(1.02)}.styles_playButton___veH8 img{vertical-align:middle}
.styles_section__fUU1f{margin-top:5.625rem}.styles_title__geb10{text-align:center;text-transform:uppercase;color:#7c8196;font-size:1rem;font-weight:500}.styles_carouselWrapper__cylxc{max-width:750px;margin:35px auto 0}@media only screen and (max-width: 767px){.styles_carouselWrapper__cylxc .carouselDesktop{display:none}}.styles_carouselWrapper__cylxc .carouselMobile{display:none}@media only screen and (max-width: 767px){.styles_carouselWrapper__cylxc .carouselMobile{display:block}}.styles_carouselWrapper__cylxc .swiper-wrapper{align-items:center}.styles_sliderItemWrapper__lmvw4{display:flex;justify-content:center;align-items:center;gap:50px}.styles_pagination__EFAD_{display:flex;justify-content:center;gap:5px;margin-top:30px}.styles_pagination__EFAD_ div{background-color:#c4c4c4;height:2px;width:21px;cursor:pointer;transition:all 300ms ease}.styles_pagination__EFAD_ div.styles_active__aaIkW{background-color:#050038}.styles_pagination__EFAD_ div.styles_semiactive__uxuu4{background-color:#82809b}.styles_customersDesktop__dHTQk{display:flex;align-items:center;justify-content:center;gap:3.125rem}

@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  width: 100%;
  padding-top: toRem(100);
}

.container {
  display: flex;
  justify-content: center;
  gap: toRem(47);
}

.title {
  font-weight: 900;
  font-size: toRem(34);
  white-space: pre;
}

.image {
  order: 1;
  width: toRem(630);

  & img {
    width: 100%;
    height: auto;
  }
}

.content {
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: toRem(20);
  max-width: toRem(525);
}

.description {
  color: $grey500;
  font-size: toRem(18);
  max-width: toRem(505);
}

@include respond(mobile) {
  .section {
    padding-top: toRem(20);
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: toRem(30);
    column-gap: unset;
  }

  .title {
    font-size: toRem(27);
  }

  .image {
    width: 100vw;
    margin-left: calc(
      50% - 50vw
    ); // centering image in full window width without brakin parent container
    overflow: hidden;
    order: 2;

    & img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    width: 100%;
    padding: 0;
    order: 1;
  }

  .description {
    padding-bottom: toRem(20);
  }

  .title {
    line-height: 1.11;
  }
}

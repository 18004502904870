@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.stats {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: start;
  background-color: $white;
  border-radius: toRem(30);
  padding: toRem(55) 0;
  border: 1px solid $black;
  max-width: min(calc(100% - 200px), 1500px);

  &Block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 toRem(30);
    position: relative;

    & + & {
      &::after {
        content: '';
        position: absolute;
        top: toRem(28);
        left: 0;
        width: 1px;
        height: toRem(50);
        background-color: $grey300;
      }
    }

    &Title {
      font-size: toRem(69);
      line-height: toRem(69);
      width: toRem(170);
      text-align: center;

      flex-wrap: nowrap;
      display: flex;
      justify-content: center;

      &Wrapper {
        display: flex;
        align-items: center;
        height: toRem(104);
      }

      & span {
        line-height: inherit;
        font-weight: 900;
      }
    }

    &Description {
      font-size: toRem(21);
      text-align: center;
    }
  }
}

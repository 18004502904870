@import '@styles/functions.module.scss';
@import '@styles/palette.module.scss';

.section {
  width: 100%;
  padding-top: toRem(100);
  padding-bottom: toRem(90);

  @include respond(mobile) {
    padding-top: toRem(30);
    padding-bottom: toRem(40);
  }
}

.title {
  font-size: 3.625rem;
  text-align: center;
  margin: 0 auto;
  white-space: pre-wrap;
  font-weight: 900;

  @include respond(mobile) {
    font-size: 42px;
    text-align: left;
    white-space: unset;
  }
}

.subtitle {
  text-align: center;
  margin-top: 15px;
  font-size: 1.375rem;
  color: $grey500;
  white-space: pre-wrap;

  @include respond(mobile) {
    text-align: left;
    white-space: normal;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 16px;

  button:last-child {
    color: $black;
  }
}

.getStarted {
  text-align: center;
  margin-top: 9px;
  font-size: 0.875rem;
  color: $black;

  a {
    color: $black;
    position: relative;
    width: calc(100% + 30px);

    svg {
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-45%);
    }
  }
}

.talkToUs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.videoArrow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: toRem(50);
}
